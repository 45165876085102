import Vue from 'vue'
import Router from 'vue-router'
import { VAuthCallback } from '@/auth'

import { discountAssociationParametersRoutes, discountRoutes } from '@invivodf/common/src/contexts/discount'
import { logisticRoutes } from '@invivodf/common/src/contexts/logistic-offer/routes/logistic-routes'
import VImportReports from '@/contexts/parameters/views/VImportReports/VImportReports.vue'
import VImportReport from '@/contexts/parameters/views/VImportReport/VImportReport.vue'
import VArticles from './views/VArticles/VArticles.vue'
import VHome from './views/VHome/VHome.vue'
import VLayoutMain from './views/VLayoutMain/VLayoutMain.vue'
import VOrders from './views/VOrders/VOrders.vue'
import VPageNotFound from './views/VPageNotFound/VPageNotFound.vue'
import VProduct from './views/VProduct/VProduct.vue'
import VParameters from './contexts/parameters/views/VParameters/VParameters.vue'
import VParametersList from './contexts/parameters/views/VParametersList/VParametersList.vue'
import VSearch from './views/VSearch/VSearch.vue'
import VVariant from './views/VVariant/VVariant.vue'
import VZoneCharges from './views/VZoneCharges/VZoneCharges.vue'
import VOffers from './views/VOffers/VOffers.vue'
import VQuotas from './views/VQuota/VQuota.vue'

import CArticleListV2 from './components/CArticleListV2/CArticleListV2.vue'
import CArticleCreation from './components/CArticleCreation/CArticleCreation.vue'
import CArticleEditV2 from './components/CArticleEditV2/CArticleEditV2.vue'
import CImportMembers from './components/CImportMembers/CImportMembers.vue'
import CImportTechnicalSales from './components/CImportTechnicalSales/CImportTechnicalSales.vue'
import CImportStores from './components/CImportStores/CImportStores.vue'
import CImportStock from './components/CImportStock/CImportStock.vue'
import CThresholdsStock from './components/thresholdsStock/CThresholdsStock/CThresholdsStock.vue'
import CImportShippingAddresses from './components/CImportShippingAddresses/CImportShippingAddresses.vue'
import CImportVariants from './components/CImportVariants/CImportVariants.vue'
import CImportQuotas from './components/CImportQuotas/CImportQuotas.vue'
import CImportOffers from './components/CImportOffers/CImportOffers.vue'
import CImportSegments from './components/CImportSegments/CImportSegments.vue'
import CDetailsPaiementsCb from './components/CDetailsPaiementsCb/CDetailsPaiementsCb.vue'
import CPaymentDueDatesList from './components/CPaymentDueDatesList/CPaymentDueDatesList.vue'

// eslint-disable-next-line import/no-cycle
import routerBeforeEach from './router/hooks/routerBeforeEach'
import VMinimalLayout from './views/VMinimalLayout/VMinimalLayout.vue'
import { VImportPartnerBanner } from './contexts/home-banner'
import CImportPartnerContact from './components/CImportPartnerContact/CImportPartnerContact.vue'
import CImportProductsAdditionalData from './components/CImportProductsAdditionalData/CImportProductsAdditionalData.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/oauth',
      component: VMinimalLayout,
      children: [
        {
          path: 'callback',
          name: 'oauth-callback',
          component: VAuthCallback,
        },
      ],
    },
    {
      path: '/',
      component: VLayoutMain,
      children: [
        {
          path: '',
          redirect: 'accueil',
        },
        {
          path: 'accueil',
          name: 'home',
          component: VHome,
        },
        {
          path: 'produits/:productId',
          name: 'product',
          component: VProduct,
        },
        {
          path: 'variant/:productId/:variantInvivoId',
          name: 'variant',
          component: VVariant,
        },
        {
          path: 'catalogue',
          component: VSearch,
          children: [
            {
              path: '',
              name: 'search-cooperative',
              component: VSearch,
            },
            {
              path: 'national',
              name: 'search-national',
              component: VSearch,
            },
            {
              path: 'cooperative',
              name: 'search-cooperative-referential',
              component: VSearch,
            },
          ],
        },
        {
          path: 'quotas',
          name: 'quotas',
          component: VQuotas,
        },
        ...discountRoutes,
        {
          path: 'commandes',
          name: 'orders',
          component: VOrders,
        },
        {
          path: 'offers',
          name: 'offers',
          component: VOffers,
        },
        ...logisticRoutes,
        {
          path: 'parameters',
          component: VParameters,
          children: [
            {
              path: '',
              name: 'parameters',
              component: VParametersList,
            },
            {
              path: 'adherents',
              name: 'import-members',
              component: CImportMembers,
            },
            {
              path: 'techniciens',
              name: 'import-technical-sales',
              component: CImportTechnicalSales,
            },
            {
              path: 'stores',
              name: 'import-stores',
              component: CImportStores,
            },
            {
              path: 'stock',
              name: 'import-stock',
              component: CImportStock,
            },
            {
              path: 'stock-thresholds',
              name: 'stock-thresholds',
              component: CThresholdsStock,
            },
            {
              path: 'adresses-de-livraison',
              name: 'import-shipping-addresses',
              component: CImportShippingAddresses,
            },
            {
              path: 'variantes',
              name: 'import-variants',
              component: CImportVariants,
            },
            {
              path: 'products-additional-data',
              name: 'import-products-additional-data',
              component: CImportProductsAdditionalData,
            },
            {
              path: 'offers',
              name: 'import-offers',
              component: CImportOffers,
            },
            {
              path: 'quotas',
              name: 'import-quotas',
              component: CImportQuotas,
            },
            {
              path: 'segments',
              name: 'import-segments',
              component: CImportSegments,
            },
            {
              path: 'contacts',
              name: 'import-contacts',
              component: CImportPartnerContact,
            },
            {
              path: 'articles',
              component: VArticles,
              children: [
                {
                  path: '',
                  name: 'articles',
                  component: CArticleListV2,
                },
                {
                  path: 'create',
                  name: 'article-creation',
                  component: CArticleCreation,
                },
                {
                  path: ':articleSlug/edit',
                  name: 'articles-v2-edit',
                  component: CArticleEditV2,
                },
              ],
            },
            {
              path: 'zone-charges',
              name: 'zone-charges',
              component: VZoneCharges,
            },
            {
              path: 'import-reports',
              name: 'import-reports',
              component: VImportReports,
            },
            {
              path: 'import-reports/detail/:importType/:importId',
              name: 'import-report',
              component: VImportReport,
            },
            {
              path: 'details-paiements-cb',
              name: 'details-paiements-cb',
              component: CDetailsPaiementsCb,
            },
            {
              path: 'partner-banner',
              name: 'partner-banner',
              component: VImportPartnerBanner,
            },
            {
              path: 'payment-due-dates',
              name: 'payment-due-dates',
              component: CPaymentDueDatesList,
            },
            ...discountAssociationParametersRoutes,
          ],
        },
      ],
    },
    {
      path: '/',
      component: VLayoutMain,
      children: [
        {
          path: '*',
          name: 'not-found',
          component: VPageNotFound,
        },
      ],
    },
  ],
})

router.beforeEach(routerBeforeEach)

export const getRouter = () => router

export default router
