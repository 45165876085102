import { AxiosInstance } from 'axios'
import { createAxiosClientWithAladinBearer } from '@/utils/axios'
import ImportService from '@/services/import.service'

export default class ProductsAdditionalDataService {
  private readonly api: AxiosInstance

  constructor(private readonly productApiHost: string | undefined) {
    this.api = createAxiosClientWithAladinBearer(`${productApiHost}/v1/cooperatives`, { timeout: 20000 })
  }

  async import(partnerId: number, file: any) {
    return await ImportService.import(`${this.productApiHost}/v1/cooperatives/${partnerId}/products`, file)
  }

  async downloadProductsAdditionalDataCSV(partnerId: number, partnerName: string) {
    const response = await this.api.get(`/${partnerId}/products`, {
      headers: { accept: 'text/csv' },
    })

    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', ImportService.createFileName(partnerName, 'products-additional-data'))
    document.body.appendChild(link)
    link.click()
  }
}
